<template>
    <div class="tw-w-full tw-px-5">
        <ul class="menu tw-mt-6 tw-w-full ">
            <li class="project-main-item tw-flex tw-flex-row tw-items-center tw-p-3">
                <img class=" tw-w-[47px] tw-h-[47px] tw-me-3  tw-rounded-full !tw-max-h-[47px] " :src="`https://placehold.co/47x47/D1D1D1/000000?text=${projectFirstLetter(clientName)}`" alt="">
                <div class=" tw-flex tw-flex-col">
                    <span class=" tw-text-[#1a202c] tw-text-[11px] tw-font-bold "> {{ clientName }}  </span>
                    <span>
                         <span class=" tw-text-[#1a202c] tw-text-[11px]" v-if="activeProject && activeProject.name">
                            {{ activeProject.name }}
                        </span>
                        <div class="tw-flex tw-items-center "  v-else>
                            <span :class="{ 'tw-h-4': $i18n.locale !== 'en' }" class=" tw-me-2" >Unnamed squad</span>
                           <span v-tooltip="$t('home.clientMissingProjectNameInfoMessage')">
                                    <img  src="@/assets/images/info-light.png" class="!tw-w-3 !tw-h-3"  />
                            </span>
                        </div> 
                    </span>
                </div>
            </li>
            <template v-if="projects.length > 1">
                <li v-for="(project, index) in projects" :key="project.id" class="project-item" :class=" { 'tw-border-0': index == projects.length - 1  } " >
                    <button 
                        class="border-white dropdown-item"
                        type="button"
                        @click="$emit('project-changed', project)"
                    >
                        <div class="project-item-avatar">
                            {{ projectFirstLetter(project.name) }}
                        </div>
                        <span v-if="!!project.name">
                            {{ project.name }}
                        </span>
                        <div class="tw-flex tw-items-center" v-else>
                            <span :class="{ 'tw-h-4': $i18n.locale !== 'en' }" class="tw-me-2">Unnamed squad</span> 
                            <span v-tooltip="$t('home.clientMissingProjectNameInfoMessage')">
                                    <img  src="@/assets/images/info-light.png" class="!tw-w-3 !tw-h-3"  />
                            </span>
                        </div>
                        <span v-if="activeProject.id == project.id" class="selected-mark">
                            ✓
                        </span>
                    </button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        clientName: {
            type: String,
            required: true,
        },
        hasNomination: {
            type: Boolean,
            default: false,
        },
        
        projects: {
            type: Array,
            default: () => [],
        },
        activeProject: {
            type: Object,
            default: null,
        },
    },
    methods: {
        projectFirstLetter(name) {
            if (name) return name[0].toUpperCase()
            else return 'U';
        },
        logOut() {
            this.$store.dispatch("auth/logOut").then(() => {
                this.$router.push({ path: "/login" });
                window.location.reload();
            });
        },
    }
}
</script>

<style lang="scss" scoped>

.project-main-item{
    background-color: rgba(238, 237, 237, 1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.project-item-avatar {
        height: 35px;
    width: 35px;
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: rgba(0, 0, 0, 1);
    line-height: 16px;
    font-weight: 700;
    margin-inline-end: 0.5rem;
}

.menu {
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(241, 241, 241, 1);
    border-radius: 12px;
    box-shadow: 0 6px 13px 0 rgba(115, 114, 117, 0.05);
    width: 100%;
}

.dropdown-item {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        margin-inline-end: 10px;
    }

    span {
        font-size: 13px;
    }

    .tooltip-wrapper {
        position: relative;
        margin-inline-start: 5px;

        span {
            font-size: 18px;
        }

        &:before {
            content: attr(data-message);
            z-index: 50000;
            position: absolute;
            display: none;
            padding: 10px;
            border-radius: 7px;
            background-color: #000;
            color: #fff;
            font-size: 10px;
            right: 0;
            top: calc(-100% - 10px);
             display: block;
        }

        &:hover {
            &:before {
                display: block;
            }
        }
    }

    .selected-mark {
        margin-inline-start: auto;
        font-weight: 900;
    }
}

.project-item {
    border-bottom: 1px solid #eee;
}

html[dir="rtl"] {
    .tooltip-wrapper {
        &:before {
            left: 0;
            right: auto;
        }
    }
}
</style>
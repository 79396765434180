<template>
  <div class="tw-flex">
    <hr v-if="hasDivider && !dividerAtStart" class="v-divider v-divider--vertical" />
    <button
      @click="setLocale($i18n.locale)"
      type="button"
      class="btn btn-white-outline lang-btn tw-text-white"
      :class="{
        'text-black': black || separatorDark,
        'bold': bold,
        'kill-hover': !black,
        'px-2 hidden-lg': separatorLight || separatorDark,
        
      }"
    >
      {{ $i18n.locale == "en" ? "عربي" : "English" }}
    </button>
   
    <hr v-if="hasDivider && dividerAtStart" class="v-divider v-divider--vertical me-3" :class="{ 'v-divider-dark': darkDivider }" />
  </div>
</template>

<script>
import { axiosInstance } from "@/network/interceptors.js";

export default {
  props: {
    black: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    darkDivider: {
      type: Boolean,
      default: false,
    },
    dividerAtStart: {
      type: Boolean,
      default: false,
    },
    separatorLight: {
      type: Boolean,
      default: false,
    },
    separatorDark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale == "en" ? "ar" : "en";
      localStorage.setItem("lang", this.$i18n.locale);
      axiosInstance.defaults.headers.common["Accept-Language"] = `${this.$i18n.locale}`;

    },
  },
};
</script>

<style lang="scss" scoped>
.btn-white-outline {
  border: 1px solid transparent !important;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 14px;
  margin-inline-end: 5px;
  font-family: "Tajawal", sans-serif !important;

  &:hover {
    border: 1px solid transparent !important;
  }
}

.text-black {
  color: #000 !important;
  font-weight: bold;
}

.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
  border-color: rgb(255 255 255) !important;
}

.v-divider-dark {
  border-color: #333 !important;
}

.v-divider--vertical {
  align-self: stretch;
  border: solid;
  border-width: 0 thin 0 0;
  display: inline-flex;
  height: inherit;
  min-height: 100%;
  max-height: 100%;
  max-width: 0;
  width: 0;
  vertical-align: text-bottom;
  margin: 0 -1px;
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.bold {
  font-weight: 600 !important;
}

.kill-hover:hover {
  text-decoration: none;
  color: #fff;
}
</style>
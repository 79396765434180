<template>
  <div class="text-center applyViewBtnWrapper" :class="{'applyViewBtnWrapper--full': wide, '!tw-rounded-full' : rounded }">
    <Button
      v-if="user.first_name"
      :text="$t('home.viewMyProfile')"
      varient="btnSecondary"
      :action="goToProfile"
      :class="{ '!tw-rounded-full' : rounded }"
    />
    <Button
      v-else
      :text="$t('talent.applyAsTalent')"
      varient="btnSecondary"
      :action="goToSignUp"
      :btnStyle=" rounded ? '!tw-rounded-full' : ''"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "./applyViewBtn.scss";
import Steps from "../../../../../enums/WizzardSteps";
import Button from "../../../../../components/shared/button/button.vue";
export default {
  components: {
    Button,
  },
  props: {
    wide: {
      type: Boolean,
      default: false,
    },
    rounded: {
       type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    localStorage: () => localStorage,
  },

  methods: {
    goToSignUp() {
      this.$router.push("/talent-signup");
    },
    goToProfile() {
      if (this.user.step == Steps.PROFILE) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "Wizard" });
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>

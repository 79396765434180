var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex"},[(_vm.hasDivider && !_vm.dividerAtStart)?_c('hr',{staticClass:"v-divider v-divider--vertical"}):_vm._e(),_c('button',{staticClass:"btn btn-white-outline lang-btn tw-text-white",class:{
      'text-black': _vm.black || _vm.separatorDark,
      'bold': _vm.bold,
      'kill-hover': !_vm.black,
      'px-2 hidden-lg': _vm.separatorLight || _vm.separatorDark,
      
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.setLocale(_vm.$i18n.locale)}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "en" ? "عربي" : "English")+" ")]),(_vm.hasDivider && _vm.dividerAtStart)?_c('hr',{staticClass:"v-divider v-divider--vertical me-3",class:{ 'v-divider-dark': _vm.darkDivider }}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }